import type {
    CalendarEntry,
    LinkedCalendarEntry,
    LinkedCalendarEntryV2,
    LocalCalendarEntry,
} from 'owa-graph-schema';
import { isOutlookConsumerAccount } from 'owa-accounts-store';
import type SubscriptionMailboxType from 'owa-service/lib/contract/SubscriptionMailboxType';
import type SubscriptionParameters from 'owa-service/lib/contract/SubscriptionParameters';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import { isGlobalSettingsMailbox } from 'owa-account-source-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isLinkedCalendarEntryV2, isOldModelSharedCalendar } from 'owa-calendar-properties';

/**
 * Return the folder, mailbox id and type needed for each particular type of calendar item subscription.
 */
export default function getSubscriptionFolderIdAndMailboxDetails(
    calendarEntry: CalendarEntry
): Pick<SubscriptionParameters, 'FolderId' | 'MailboxId' | 'MailboxType'> | null {
    const { FolderId } = calendarEntry as LocalCalendarEntry;
    // For group calendars the group name is sufficient.
    // The server will subscribe using OWA's CalendarItemBrokerHandler and the Broker's OwaCalendarItemNotificationHandler.
    if (calendarEntry.IsGroupMailboxCalendar) {
        const emailAddress = (calendarEntry as LinkedCalendarEntry).OwnerEmailAddress;
        const userIdentity = calendarEntry.calendarId.mailboxInfo.userIdentity;
        return {
            FolderId: FolderId?.Id,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (25,13): Type 'Maybe<string> | undefined' is not assignable to type 'string | undefined'.
            // @ts-expect-error
            MailboxId: emailAddress,
            MailboxType: isConsumer(userIdentity) ? 1 : 0,
        };
    }

    // Check for old model shared calendars and get email address of the owner and remote folder id.
    // The server will subscribe using the Notification Platform.
    if (
        (isFeatureEnabled(
            'cal-Notifications-HybridModel-SharedCalendars',
            calendarEntry.calendarId.mailboxInfo,
            false /* dontThrowErrorIfNotInitialized */
        ) &&
            isLinkedCalendarEntryV2(calendarEntry)) ||
        (isFeatureEnabled(
            'cal-Notifications-OldModel-SharedCalendars',
            calendarEntry.calendarId.mailboxInfo,
            false /* dontThrowErrorIfNotInitialized */
        ) &&
            isOldModelSharedCalendar(calendarEntry))
    ) {
        const linkedCalendarEntry = isLinkedCalendarEntryV2(calendarEntry)
            ? (calendarEntry as LinkedCalendarEntryV2)
            : (calendarEntry as LinkedCalendarEntry);
        const emailAddress = linkedCalendarEntry.OwnerEmailAddress;
        const mailboxInfo = linkedCalendarEntry.calendarId.mailboxInfo;
        const userIdentity = mailboxInfo?.userIdentity;

        return isGlobalSettingsMailbox(mailboxInfo) ||
            !isConsumer(userIdentity) ||
            isMonarchMultipleAccountsEnabled()
            ? {
                  FolderId: linkedCalendarEntry.SharedFolderId?.Id,
                  MailboxId: emailAddress ?? undefined,
              }
            : {
                  FolderId: linkedCalendarEntry.SharedFolderId?.Id,
                  MailboxId: emailAddress ?? undefined,
                  MailboxType: isOutlookConsumerAccount(userIdentity) ? 2 : 3,
              };
    }

    // For local calendars on the primary account, use the folderId.
    // The server will subscribe using the MAPI notification handler directly.
    // For calendars from a consumer account, we need to send the folderId,
    // mailbox id and the mailbox type.
    if (FolderId) {
        const mailboxInfo = calendarEntry.calendarId.mailboxInfo;
        return isGlobalSettingsMailbox(mailboxInfo) ||
            !isConsumer(mailboxInfo.userIdentity) ||
            isMonarchMultipleAccountsEnabled()
            ? { FolderId: FolderId.Id }
            : {
                  FolderId: FolderId.Id,
                  MailboxId: mailboxInfo.userIdentity,
                  MailboxType: isOutlookConsumerAccount(mailboxInfo.userIdentity) ? 2 : 3,
              };
    }

    // If we add support for other types of calendars, include their case here.
    // For example, can we subscribe to "old-model" shared calendars, where we have a LinkedCalendarEntry
    // that is NOT a group mailbox and has the owner's e-mail address in OwnerEmailAddress?
    // I've tried, but got "The notification receiver does not have enough permission to make this subscription."

    // Unsupported cases should return null so we don't bombard the server with requests that cannot be fulfilled.
    return null;
}
