import type { MailboxInfo } from 'owa-client-types';
import getCalendarEntryByFolderId from '../selectors/getCalendarEntryByFolderId';
import {
    getCoprincipalMailboxInfoForMailboxInfo,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';

export function getCoprincipalMailboxInfoFromFolderId(folderId?: string): MailboxInfo {
    if (!folderId) {
        return getGlobalSettingsAccountMailboxInfo();
    }

    const calendarEntryMailboxInfo =
        getCalendarEntryByFolderId(folderId)?.calendarId.mailboxInfo ??
        getGlobalSettingsAccountMailboxInfo();

    return (
        getCoprincipalMailboxInfoForMailboxInfo(calendarEntryMailboxInfo) ??
        getGlobalSettingsAccountMailboxInfo()
    );
}
