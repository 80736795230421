import { orchestrator } from 'satcheljs';
import { updateNumDaysInDayRange } from '../actions/updateNumDaysInDayRange';
import { updateNumDaysInDayRangeServerValue } from '../utils/updateFlexibleDayViewValues';
import { resetNumDaysInDayRange } from '../actions/resetNumDaysInDayRange';
import { logCoreUsage } from 'owa-analytics';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(updateNumDaysInDayRange, actionMessage => {
    const { newValue, isUserChange } = actionMessage;
    // only log if this is a user change, since we get the persisted state logged in InitialCalendarSurfaceOptions
    if (isUserChange) {
        logCoreUsage('updateNumDaysInDayRange', { numDaysInDayRange_1: newValue });
    }
    updateNumDaysInDayRangeServerValue(newValue);
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(resetNumDaysInDayRange, actionMessage => {
    // only log if this is a user change, since we get the persisted state logged in InitialCalendarSurfaceOptions
    if (actionMessage.isUserChange) {
        logCoreUsage('updateNumDaysInDayRange', { numDaysInDayRange_1: 1 });
    }
    updateNumDaysInDayRangeServerValue(1);
});
