import type { CalendarEntry, LocalCalendarEntry } from 'owa-graph-schema';

export default function isWritableCalendar(calendarEntry: CalendarEntry | null): boolean {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (7,5): Type 'boolean | null | undefined' is not assignable to type 'boolean'.
    // @ts-expect-error
    return (
        calendarEntry &&
        !(calendarEntry as LocalCalendarEntry).IsReadOnly &&
        calendarEntry.EffectiveRights &&
        calendarEntry.EffectiveRights.CreateContents
    );
}
