import {
    insertCalendarIdInOrderedList,
    insertCalendarGroupInOrderedList,
    deleteCalendarIdFromOrderedList,
    deleteCalendarGroupFromOrderedList,
} from '../actions/publicActions';
import getStore from '../store/store';
import { mutator } from 'satcheljs';
import { findInArray } from 'owa-calendar-data-utils/lib/findInArray';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export const insertCalendarIdInOrderedListMutator = mutator(
    insertCalendarIdInOrderedList,
    ({ index, idToAdd }) => {
        if (idToAdd) {
            const store = getStore();
            // Add calendar list item
            if (typeof index == 'number' && index > -1) {
                // Item already exists
                store.calendarIdOrderedList.splice(index, 0, idToAdd);
            } else {
                // Item doesnt exist
                store.calendarIdOrderedList.push(idToAdd);
            }

            store.calendarIdOrderedList = removeDuplicatesFromOrderedList(
                store.calendarIdOrderedList
            );
        }
    }
);

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export const deleteCalendarIdFromOrderedListMutator = mutator(
    deleteCalendarIdFromOrderedList,
    ({ calendarIdKey }) => {
        removeEntryFromOrderedIdList(getStore().calendarIdOrderedList, calendarIdKey);
    }
);

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export const insertCalendarGroupInOrderedListMutator = mutator(
    insertCalendarGroupInOrderedList,
    ({ index, idToAdd }) => {
        const store = getStore();
        // Add calendar group list item
        if (idToAdd) {
            if (index > -1) {
                // Item already exists
                store.calendarGroupKeyOrderedList.splice(index, 0, idToAdd);
            } else {
                // Item doesnt exist
                store.calendarGroupKeyOrderedList.push(idToAdd);
            }

            store.calendarGroupKeyOrderedList = removeDuplicatesFromOrderedList(
                store.calendarGroupKeyOrderedList
            );
        }
    }
);

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export const deleteCalendarGroupFromOrderedListMutator = mutator(
    deleteCalendarGroupFromOrderedList,
    ({ groupKey }) => {
        removeEntryFromOrderedIdList(getStore().calendarGroupKeyOrderedList, groupKey);
    }
);

function removeDuplicatesFromOrderedList(orderedList: string[]): string[] {
    const dedupedOrderedList: string[] = [];
    const uniqueKeyMapping = new Map();

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    orderedList.forEach(calendarId => {
        if (!uniqueKeyMapping.has(calendarId)) {
            dedupedOrderedList.push(calendarId);
            uniqueKeyMapping.set(calendarId, null);
        }
    });

    return dedupedOrderedList;
}

function removeEntryFromOrderedIdList(orderedIdList: string[], EntryKey: string) {
    const orderedIdListFindResult = findInArray(orderedIdList, id => id === EntryKey);

    if (orderedIdListFindResult) {
        orderedIdList.splice(orderedIdListFindResult.index, 1);
    }
}
