import type { IComputedValue } from 'mobx';
import { computed } from 'mobx';
import type { CalendarSurfaceOptions } from 'owa-outlook-service-option-store';
import { getDefaultOptions } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

const getNumDaysInDayRangeComputed: IComputedValue<number> = computed(() => {
    let numDaysInDayRange = 0;
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );

    numDaysInDayRange = userOptions.numDaysInDayRange;

    // if numDaysInDayRange is zero, that means it has never been set before, in that case use the default value
    if (numDaysInDayRange == 0) {
        const defaultOptions = getDefaultOptions()[
            OwsOptionsFeatureType.CalendarSurfaceOptions
        ] as CalendarSurfaceOptions;
        numDaysInDayRange = defaultOptions.numDaysInDayRange;
    }
    return numDaysInDayRange;
});

export default function getNumDaysInDayRange(): number {
    return getNumDaysInDayRangeComputed.get();
}
