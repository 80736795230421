import type { ClientFolderId } from 'owa-client-ids';
import {
    getGlobalSettingsAccountMailboxInfo,
    getMailboxInfoForCalendarAccounts,
} from 'owa-account-source-list-store';
import {
    getFolderIdByCalendarID,
    getDefaultCalendar,
    isWritableCalendar,
} from 'owa-calendar-cache';
import { getSelectedCalendarEntriesForUser } from 'owa-calendar-module-calendarslist-utils';
import type { CalendarEntry } from 'owa-graph-schema';
import { getAccountKeyForMailboxInfo, type MailboxInfo } from 'owa-client-types';

/**
 * Returns the calendar folder Id (Parent folder Id for a CalendarEvent) that needs to be selected by default in the selector
 */
export default function initializeCalendarFolderId(
    defaultMailboxInfo?: MailboxInfo
): ClientFolderId {
    const accountKey = defaultMailboxInfo
        ? getAccountKeyForMailboxInfo(defaultMailboxInfo)
        : undefined;
    const primaryDefaultCalendar = getDefaultCalendar(accountKey);
    const primaryMailboxInfo =
        primaryDefaultCalendar?.calendarId.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();

    const primaryCalendarFolderId = getDefaultFolderIdFromSelected(primaryMailboxInfo);
    // Check if there are any valid folder ids in primary account, return if it exists
    if (primaryCalendarFolderId) {
        return primaryCalendarFolderId;
    }

    // Walk thru all the calendar accounts and return the first one that has a valid default folder ID
    const mailboxInfos = getMailboxInfoForCalendarAccounts();

    for (let index = 0; index < mailboxInfos.length; index++) {
        const mailboxInfo = mailboxInfos[index];
        const folderId = getDefaultFolderIdFromSelected(mailboxInfo);

        if (folderId) {
            return folderId;
        }
    }

    // in all other cases, return primary account default calendar
    let defaultCalendarFolderId = null;
    let mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    if (primaryDefaultCalendar) {
        mailboxInfo = primaryDefaultCalendar.calendarId.mailboxInfo;
        defaultCalendarFolderId = getFolderIdByCalendarID(primaryDefaultCalendar.calendarId.id);
    }

    return {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (56,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        Id: defaultCalendarFolderId,
        mailboxInfo,
    };
}

/**
 * Gets the default folder id to use in new event compose from a list of selected writable
 * calendars from a given default calendar's primary account.
 * @param selectedCalendars list of selected writable calendars to choose from
 * @param defaultCalendar default calendar from the list provided
 */
function getDefaultFolderIdFromSelected(mailboxInfo: MailboxInfo): ClientFolderId | null {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const defaultCalendar = getDefaultCalendar(accountKey);
    const selectedCalendars: CalendarEntry[] | null = defaultCalendar
        ? getSelectedWritableCalendars(defaultCalendar.calendarId.mailboxInfo)
        : null;

    const numberCalendars = selectedCalendars ? selectedCalendars.length : 0;
    let selectedCalendar = defaultCalendar;

    if (selectedCalendars && numberCalendars > 0) {
        // Select the last calendar because it is the calendar that was most recently selected by the user
        selectedCalendar = selectedCalendars[numberCalendars - 1];
        // Check to see if the default calendar is in selected list, if so use that
        if (defaultCalendar) {
            selectedCalendars.forEach(calendar => {
                if (calendar.calendarId.id == defaultCalendar.calendarId.id) {
                    selectedCalendar = defaultCalendar;
                }
            });
        }
        return {
            Id: getFolderIdByCalendarID(selectedCalendar.calendarId.id),
            mailboxInfo: selectedCalendar.calendarId.mailboxInfo,
        } as ClientFolderId;
    }
    return null;
}

/**
 * Get the list of writable calendars from the selected calendars.
 * @param mailboxInfo mailbox info
 */
function getSelectedWritableCalendars(mailboxInfo: MailboxInfo): CalendarEntry[] | null {
    const selectedCalendars = getSelectedCalendarEntriesForUser(mailboxInfo);

    return selectedCalendars ? selectedCalendars.filter(isWritableCalendar) : null;
}
