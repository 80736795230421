import { mutator } from 'satcheljs';
import { updateNumDaysInDayRange } from '../actions/updateNumDaysInDayRange';
import { resetNumDaysInDayRange } from '../actions/resetNumDaysInDayRange';
import { updateNumDaysInDayRangeStoredValue } from '../utils/updateFlexibleDayViewValues';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(updateNumDaysInDayRange, actionMessage => {
    const { newValue } = actionMessage;
    updateNumDaysInDayRangeStoredValue(newValue);
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(resetNumDaysInDayRange, () => {
    updateNumDaysInDayRangeStoredValue(1);
});
