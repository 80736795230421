import type { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import loc, { format } from 'owa-localize';
import {
    formatTimeScaleLabel,
    formatTimeScaleWithDescriptionLabel,
    leastSpaceTimeScaleOptionDescription,
    mostSpaceTimeScaleOptionDescription,
} from './getTimeScaleSettings.locstring.json';
import type { TimeScaleSetting } from '../schema/TimeScaleSetting';
import { isFeatureEnabled } from 'owa-feature-flags';

const TIMESCALE_PREFIX = 'timescale_';
const defaultTimeScaleSettings = [60, 30, 15, 10, 6, 5];

export const DEFAULT_TIME_SCALE = 30;
const EXPANDED_TIME_SCALE = 40;

export function getTimeScaleSettingsMenuItems(
    selectedSetting: TimeScaleSetting,
    onChangeSetting: (newSetting: TimeScaleSetting) => void
): IContextualMenuItem[] {
    return getTimeScaleSettings().map(setting => {
        return {
            key: TIMESCALE_PREFIX + setting.toString(),
            text: getTimeScaleSettingLabel(setting),
            canCheck: true,
            checked: setting === selectedSetting,
            onClick: () => {
                if (selectedSetting !== setting) {
                    onChangeSetting(setting);
                }
            },
        };
    });
}

export function convertTimeScaleSettingToWeekViewPixelsPerMinuteRatio(setting: TimeScaleSetting) {
    return getTimescale(setting) / setting;
}

export function getTimeScaleSettings(): TimeScaleSetting[] {
    return Object.values(defaultTimeScaleSettings).map(setting => setting as TimeScaleSetting);
}

export function getTimeScaleSettingLabel(setting: TimeScaleSetting) {
    const baseDescription = format(loc(formatTimeScaleLabel), setting);
    switch (setting) {
        case defaultTimeScaleSettings[0]:
            return format(
                loc(formatTimeScaleWithDescriptionLabel),
                baseDescription,
                loc(leastSpaceTimeScaleOptionDescription)
            );
        case defaultTimeScaleSettings[defaultTimeScaleSettings.length - 1]:
            return format(
                loc(formatTimeScaleWithDescriptionLabel),
                baseDescription,
                loc(mostSpaceTimeScaleOptionDescription)
            );
        default:
            return baseDescription;
    }
}

export function getTimescale(setting: TimeScaleSetting): number {
    return isFeatureEnabled('cal-surface-timescale-height-adjustments') &&
        (setting == 60 || setting == 30)
        ? EXPANDED_TIME_SCALE
        : DEFAULT_TIME_SCALE;
}
