import type { CalendarEntry } from 'owa-graph-schema';
import type { AccountKey } from 'owa-client-types';
import { isSameCoprincipalAccountByMailboxAndAccountKey } from 'owa-client-types';

export function isCalendarInAccount(calendar: CalendarEntry, accountKey: AccountKey): boolean {
    return isSameCoprincipalAccountByMailboxAndAccountKey(
        calendar?.calendarId.mailboxInfo,
        accountKey
    );
}

export function isCalendarInGroup(calendarEntry: CalendarEntry, groupId: string): boolean {
    return !!calendarEntry && calendarEntry.ParentGroupId === groupId;
}

export function isSameCalendar(cal1: CalendarEntry, cal2: CalendarEntry): boolean {
    return !!cal1 && !!cal2 && cal1.calendarId.id === cal2.calendarId.id;
}

export function compareCalendarsByName(a: CalendarEntry, b: CalendarEntry) {
    return a.CalendarName.localeCompare(b.CalendarName);
}
