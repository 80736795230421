import type { CalendarSurfaceOptions } from 'owa-outlook-service-option-store';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

export default function getIsDynamicColumnWidthEnabled(): boolean {
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );

    return userOptions.isDynamicColumnWidthEnabled;
}
