import { orchestrator } from 'satcheljs';
import { updateCollapsedAllDayWellHeight } from '../actions/updateCollapsedAllDayWellHeight';
import type { CalendarSurfaceOptions } from 'owa-outlook-service-option-store';
import { lazyCreateOrUpdateOptionsForFeature } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

const SCROLL_DELAY = 3000;
let onScrollTimeoutTask: ReturnType<typeof setTimeout> | null = null;

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(updateCollapsedAllDayWellHeight, actionMessage => {
    if (onScrollTimeoutTask) {
        clearTimeout(onScrollTimeoutTask);
    }
    onScrollTimeoutTask = setTimeout(() => {
        const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
            OwsOptionsFeatureType.CalendarSurfaceOptions
        );
        onScrollTimeoutTask = null;

        lazyCreateOrUpdateOptionsForFeature.importAndExecute(
            OwsOptionsFeatureType.CalendarSurfaceOptions,
            {
                ...userOptions,
                allDayWellHeight: actionMessage.newValue,
            } as CalendarSurfaceOptions
        );
    }, SCROLL_DELAY);
});
