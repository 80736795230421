import { getSelectedCalendarsForUser } from 'owa-calendar-module-selected-calendars-user-config/lib/selectors/getSelectedCalendarsForUser';
import { getSortedCalendarEntriesFromIds } from './getSortedCalendarEntriesFromIds';
import type { CalendarEntry } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';

export function getSelectedCalendarEntriesForUser(mailboxInfo: MailboxInfo): CalendarEntry[] {
    const selectedCalendars = getSelectedCalendarsForUser(mailboxInfo);
    const calEntries = getSortedCalendarEntriesFromIds(selectedCalendars, mailboxInfo);

    return calEntries ? calEntries : [];
}
