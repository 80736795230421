import type {
    CalendarEntry,
    CalendarGroup,
    CalendarId,
    LocalCalendarEntry,
} from 'owa-graph-schema';
import type CalendarFolder from 'owa-service/lib/contract/CalendarFolder';
import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

export const initializeCalendarsCacheInStore = action(
    'initializeCalendarsCacheInStore',
    (
        defaultCalendarEntry: CalendarEntry,
        calendarEntries: {
            [key: string]: CalendarEntry;
        },
        calendarGroups: {
            [key: string]: CalendarGroup;
        },
        folderIdMapping: {
            [key: string]: CalendarId;
        }
    ) => {
        return {
            defaultCalendarEntry,
            calendarEntries,
            calendarGroups,
            folderIdMapping,
        };
    }
);

export const addToCalendarsCache = action(
    'addToCalendarsCache',
    (calendarEntries: CalendarEntry[], indexToInsert: number = -1) => {
        return {
            calendarEntries,
            indexToInsert,
        };
    }
);

export const addCalendarGroupToCalendarsCache = action(
    'addCalendarGroupToCalendarsCache',
    (calendarGroup: CalendarGroup, indexToInsert: number = -1) => {
        return {
            calendarGroup,
            indexToInsert,
        };
    }
);

export const removeCalendarGroupFromCalendarsCache = action(
    'removeCalendarGroupFromCalendarsCache',
    (calendarGroup: CalendarGroup) => {
        return {
            calendarGroup,
        };
    }
);

export const updateCalendarEntry = action(
    'updateCalendarEntry',
    (id: string, calendarEntry: Partial<CalendarEntry | LocalCalendarEntry>) => ({
        id,
        calendarEntry,
    })
);

export const updateCalendarFolder = action(
    'updateCalendarFolder',
    (calendar: CalendarEntry, calendarFolder: Partial<CalendarFolder>) => ({
        calendar,
        calendarFolder,
    })
);

export const markCalendarEntryAsValid = action(
    'markCalendarEntryAsValid',
    (calendarEntry: CalendarEntry) => ({ calendarEntry })
);

export const removeCalendarWithIDFromCalendarsCache = action(
    'removeCalendarWithIDFromCalendarsCache',
    (calendarId: string, shouldPersistCalendarEntry: boolean = false) => ({
        calendarId,
        shouldPersistCalendarEntry,
    })
);

export const updateCalendarGroup = action(
    'updateCalendarGroup',
    (groupId: string, mailboxInfo: MailboxInfo, calendarGroup: Partial<CalendarGroup>) => ({
        groupId,
        mailboxInfo,
        calendarGroup,
    })
);

export const insertCalendarIdInOrderedList = action(
    'insertCalendarIdInOrderedList',
    (index?: number, idToAdd?: string) => ({
        index,
        idToAdd,
    })
);

export const deleteCalendarIdFromOrderedList = action(
    'deleteCalendarIdFromOrderedList',
    (calendarIdKey: string) => ({
        calendarIdKey,
    })
);

export const insertCalendarGroupInOrderedList = action(
    'insertCalendarGroupInOrderedList',
    (index: number, idToAdd?: string) => ({
        index,
        idToAdd,
    })
);

export const deleteCalendarGroupFromOrderedList = action(
    'deleteCalendarGroupFromOrderedList',
    (groupKey: string) => ({
        groupKey,
    })
);

export const removeCalendar = action('removeCalendar', (folderId: string) => ({
    folderId,
}));

export const removeCalendarsForUser = action(
    'removeCalendarsForUser',
    (mailboxInfo: MailboxInfo) => ({
        mailboxInfo,
    })
);
