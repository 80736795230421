import { mutator } from 'satcheljs';
import { updateTimeScaleSetting } from '../actions/updateTimeScaleSetting';
import type { CalendarSurfaceOptions } from 'owa-outlook-service-option-store';
import { OwsOptionsFeatureType, getOptionsForFeature } from 'owa-outlook-service-option-store';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(updateTimeScaleSetting, actionMessage => {
    const { newValue } = actionMessage;
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );
    if (userOptions.timeScaleSetting != newValue) {
        userOptions.timeScaleSetting = newValue;
    }
});
