import { initializeCalendarsCacheInStore } from '../actions/publicActions';
import type { ObservableMap } from 'mobx';
import getStore from '../store/store';
import { mutator } from 'satcheljs';
import { registerCalendarCacheCollector } from '../utils/registerCalendarCacheCollector';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';

function addEntries<T>(
    map: ObservableMap<string, T>,
    obj: {
        [key: string]: T;
    }
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(obj).forEach(key => map.set(key, obj[key]));
}

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * There is one mutator defined in another package for this action, thus it cannot be changed to mutatorAction */
mutator(
    initializeCalendarsCacheInStore,
    ({ defaultCalendarEntry, calendarEntries, folderIdMapping, calendarGroups }) => {
        const store = getStore();
        if (defaultCalendarEntry) {
            const accountKey = getAccountKeyForMailboxInfo(
                defaultCalendarEntry.calendarId.mailboxInfo
            );
            store.defaultCalendars.set(accountKey, defaultCalendarEntry);
        }
        addEntries(store.calendarEntryMapping, calendarEntries);

        store.calendarIdOrderedList = store.calendarIdOrderedList.concat(
            Object.keys(calendarEntries)
        );
        addEntries(getStore().folderIdToCalendarId, folderIdMapping);

        addEntries(store.calendarGroupsMapping, calendarGroups);

        store.calendarGroupKeyOrderedList = store.calendarGroupKeyOrderedList.concat(
            Object.keys(calendarGroups)
        );

        // Register collector for diagnostics data now that there is at least one calendar in the store
        registerCalendarCacheCollector();
    }
);
