import { isWithinRange, type OwaDate, getTimestamp } from 'owa-datetime';
import { getOwaDateFromExtendedDateTime } from 'owa-datetime-utils';
import type { ExtendedDateTime } from 'owa-graph-schema';

/**
 * Checks if a given FWH view segment is encapsulated by another set of FWH view segments.
 * For example, if segment A is 9am-11am, then it is encapsulated by segment B of 7am-12pm.
 *
 * @param segment A working hours view segment.
 * @param otherViewSegments An array of view segments.
 * @returns A boolean that indicates whether or not the given view segment is encapsulated
 * by the set of other view segment definitions.
 */
export default function isSegmentEncapsulated(
    segment: {
        start: ExtendedDateTime;
        end: ExtendedDateTime;
    },
    otherViewSegments: Array<{
        start: ExtendedDateTime;
        end: ExtendedDateTime;
    }>
) {
    const mergedOverlappingViewSegments: Array<{
        start: OwaDate;
        end: OwaDate;
    }> = mergeOverlappingViewSegments(otherViewSegments);

    const segmentAsOwaDate = convertSegmentToOwaDate(segment);
    return mergedOverlappingViewSegments.some(otherSegment => {
        return (
            isWithinRange(segmentAsOwaDate.start, otherSegment.start, otherSegment.end) &&
            isWithinRange(segmentAsOwaDate.end, otherSegment.start, otherSegment.end)
        );
    });
}

/**
 * Merges together overlapping view segments. For example, if there are two segments
 * A: 9am-11am and B: 10am-12pm, the result would be a single segment of 9am-12pm.
 *
 * @param otherViewSegments An array of view segments.
 * @returns A sorted array of view segments that have been merged together as needed.
 */
function mergeOverlappingViewSegments(
    otherViewSegments: Array<{
        start: ExtendedDateTime;
        end: ExtendedDateTime;
    }>
): Array<{
    start: OwaDate;
    end: OwaDate;
}> {
    if (otherViewSegments.length === 0) {
        return [];
    }

    const mergedViewSegments: Array<{
        start: OwaDate;
        end: OwaDate;
    }> = [];
    const sortedViewSegments = otherViewSegments.sort((a, b) => {
        const aStart = getOwaDateFromExtendedDateTime(a.start);
        const bStart = getOwaDateFromExtendedDateTime(b.start);
        return getTimestamp(aStart) - getTimestamp(bStart);
    });

    let currentSegment = convertSegmentToOwaDate(sortedViewSegments[0]);
    for (let i = 1; i < sortedViewSegments.length; i++) {
        const currentSegmentEndTimestamp = getTimestamp(currentSegment.end);
        const nextSegment = convertSegmentToOwaDate(sortedViewSegments[i]);
        if (
            currentSegmentEndTimestamp >= getTimestamp(nextSegment.start) &&
            currentSegmentEndTimestamp <= getTimestamp(nextSegment.end)
        ) {
            currentSegment.end = nextSegment.end;
        } else {
            mergedViewSegments.push(currentSegment);
            currentSegment = nextSegment;
        }
    }

    mergedViewSegments.push(currentSegment);
    return mergedViewSegments;
}

function convertSegmentToOwaDate(segment: { start: ExtendedDateTime; end: ExtendedDateTime }): {
    start: OwaDate;
    end: OwaDate;
} {
    return {
        start: getOwaDateFromExtendedDateTime(segment.start),
        end: getOwaDateFromExtendedDateTime(segment.end),
    };
}
