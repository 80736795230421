import { getNonNullCalendarsFromCalendarIds } from 'owa-calendar-cache';
import { sortCalendarEntries } from './sortCalendarEntries';
import type { CalendarEntry } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';

export function getSortedCalendarEntriesFromIds(
    calendarIds: string[],
    mailboxInfo: MailboxInfo
): CalendarEntry[] {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const calEntries = getNonNullCalendarsFromCalendarIds(calendarIds);
    const sortedCalendars = sortCalendarEntries(calEntries, accountKey);

    return sortedCalendars;
}
