import type { IComputedValue } from 'mobx';
import { computed } from 'mobx';
import type { CalendarSurfaceOptions } from 'owa-outlook-service-option-store';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import UserOptionsDefaultConstants from '../constants';

const getAllDayWellHeightComputed: IComputedValue<number> = computed(() => {
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );

    if (userOptions.allDayWellHeight == 0) {
        return UserOptionsDefaultConstants.defaultAllDayWellCollapsedHeight;
    } else {
        return userOptions.allDayWellHeight;
    }
});

export default function getAllDayWellHeight(): number {
    return getAllDayWellHeightComputed.get();
}
