import { action } from 'satcheljs';
import type { TimeScaleSetting, TimeScaleSource } from 'owa-calendar-timescale';
import type { DateRangeType } from '@fluentui/date-time-utilities/lib/dateValues/dateValues';

export const updateTimeScaleSetting = action(
    'updateTimeScaleSetting',
    (newValue: TimeScaleSetting, source: TimeScaleSource, dateRangeType?: DateRangeType) => ({
        newValue,
        source,
        dateRangeType,
    })
);
