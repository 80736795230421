import type { IComputedValue } from 'mobx';
import { computed } from 'mobx';
import getTimeScaleSetting from './getTimeScaleSetting';
import { convertTimeScaleSettingToWeekViewPixelsPerMinuteRatio } from 'owa-calendar-timescale';

const getSurfaceGridPixelsPerMinuteRatioComputed: IComputedValue<number> = computed(() => {
    const timeScaleSetting = getTimeScaleSetting();
    return convertTimeScaleSettingToWeekViewPixelsPerMinuteRatio(timeScaleSetting);
});

export default function getSurfaceGridPixelsPerMinuteRatio(): number {
    return getSurfaceGridPixelsPerMinuteRatioComputed.get();
}
