import {
    addCalendarGroupToCalendarsCache,
    addToCalendarsCache,
    removeCalendarGroupFromCalendarsCache,
    removeCalendarWithIDFromCalendarsCache,
    insertCalendarIdInOrderedList,
    insertCalendarGroupInOrderedList,
    deleteCalendarGroupFromOrderedList,
    deleteCalendarIdFromOrderedList,
    removeCalendarsForUser,
} from '../actions/publicActions';
import getCalendarGroupKey from '../utils/getCalendarGroupKey';
import { getCalendarsForUser, getFolderIdByCalendarID } from '../selectors/calendarsCacheSelectors';
import getStore from '../store/store';
import { getCalculatedFolderId } from 'owa-calendar-properties';
import { orchestrator } from 'satcheljs';
import { setCalendarEntryMappingForId } from '../mutators/calendarEntryMutators';
import { setCalendarFolderIdToCalendarId } from '../mutators/calendarFolderMutators';
import { setCalendarGroupMappingForKey } from '../mutators/calendarGroupMutators';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(addToCalendarsCache, actionMessage => {
    const { calendarEntries, indexToInsert } = actionMessage;

    for (const calendarEntry of calendarEntries) {
        setCalendarEntryMappingForId(calendarEntry.calendarId.id, calendarEntry);
        insertCalendarIdInOrderedList(indexToInsert, calendarEntry.calendarId.id);

        const folderId = getCalculatedFolderId(calendarEntry);
        if (folderId) {
            setCalendarFolderIdToCalendarId(folderId.Id, {
                id: calendarEntry.calendarId.id,
                changeKey: calendarEntry.calendarId.changeKey,
                mailboxInfo: calendarEntry.calendarId.mailboxInfo,
            });
        }
    }
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(addCalendarGroupToCalendarsCache, actionMessage => {
    const { calendarGroup, indexToInsert } = actionMessage;
    const accountKey = getAccountKeyForMailboxInfo(calendarGroup.calendarGroupId.mailboxInfo);
    const groupKey = getCalendarGroupKey(accountKey, calendarGroup.serverGroupId);

    setCalendarGroupMappingForKey(groupKey, calendarGroup);
    insertCalendarGroupInOrderedList(indexToInsert, groupKey);
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(removeCalendarGroupFromCalendarsCache, actionMessage => {
    const { calendarGroup } = actionMessage;
    const accountKey = getAccountKeyForMailboxInfo(calendarGroup.calendarGroupId.mailboxInfo);
    const store = getStore();
    const groupKey = getCalendarGroupKey(accountKey, calendarGroup.serverGroupId);

    store.calendarGroupsMapping.delete(groupKey);
    deleteCalendarGroupFromOrderedList(groupKey);
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(removeCalendarWithIDFromCalendarsCache, actionMessage => {
    const { calendarId, shouldPersistCalendarEntry } = actionMessage;

    // When we get a folder value from getCalendarFolderconfig, calendarEntry map/s key is unchanged
    // and remoteCategories are added in the value, if present. Hence there is no need to delete and re-add
    // the same entry in the calendarEntryMapping map, so persist it - in those cases shouldPersistCalendarEntry is sent as True (default value for this action)
    removeCalendarWithId(calendarId, shouldPersistCalendarEntry);
});

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(removeCalendarsForUser, ({ mailboxInfo }) => {
    const calendarEntries = getCalendarsForUser(mailboxInfo);
    for (const calendar of calendarEntries) {
        removeCalendarWithId(calendar.calendarId.id, false /* shouldPersistCalendarEntry */);
    }
});

function removeCalendarWithId(calendarId: string, shouldPersistCalendarEntry?: boolean) {
    const folderId = getFolderIdByCalendarID(calendarId);
    const store = getStore();
    deleteCalendarIdFromOrderedList(calendarId);

    if (!shouldPersistCalendarEntry) {
        store.calendarEntryMapping.delete(calendarId);
        store.validEntryMapping.delete(calendarId);
    }

    if (folderId) {
        store.folderIdToCalendarId.delete(folderId);
    }
}
