import {
    getCalendarEntryByCalendarId,
    getDefaultCalendar,
} from '../selectors/calendarsCacheSelectors';
import { mutator, mutatorAction } from 'satcheljs';
import { updateCalendarFolder } from '../actions/publicActions';
import getStore from '../store/store';
import type { CalendarId } from 'owa-graph-schema';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(updateCalendarFolder, actionMessage => {
    const { calendar, calendarFolder } = actionMessage;

    const calendarEntryInStore = getCalendarEntryByCalendarId(calendar.calendarId.id);
    if (!calendarEntryInStore) {
        return;
    }

    const accountKey = getAccountKeyForMailboxInfo(calendarEntryInStore.calendarId.mailboxInfo);
    const defaultCalendar = getDefaultCalendar(accountKey);
    const shouldUpdateDefaultCalendar =
        defaultCalendar && defaultCalendar.calendarId.id === calendarEntryInStore.calendarId.id;

    Object.assign(calendarEntryInStore, calendarFolder);
    if (shouldUpdateDefaultCalendar) {
        Object.assign(defaultCalendar, calendarFolder);
    }
});

export const setCalendarFolderIdToCalendarId = mutatorAction(
    'setCalendarFolderIdToCalendarId',
    (folderId: string, calendarId: CalendarId) => {
        getStore().folderIdToCalendarId.set(folderId, calendarId);
    }
);
