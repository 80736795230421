import { orchestrator } from 'satcheljs';
import { updateIsDynamicColumnWidthEnabled } from '../actions/updateIsDynamicColumnWidthEnabled';
import { updateSurfaceOptions } from 'owa-calendar-options-actions';
import type { CalendarSurfaceOptions } from 'owa-outlook-service-option-store';
import { lazyCreateOrUpdateOptionsForFeature } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';
import { logUsage } from 'owa-analytics';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(updateIsDynamicColumnWidthEnabled, actionMessage => {
    const { newValue } = actionMessage;
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );

    lazyCreateOrUpdateOptionsForFeature.importAndExecute(
        OwsOptionsFeatureType.CalendarSurfaceOptions,
        {
            ...userOptions,
            isDynamicColumnWidthEnabled: newValue,
        } as CalendarSurfaceOptions
    );

    logUsage('IsDynamicColumnWidthEnabledUpdated', {
        newValue_1: newValue,
    });

    updateSurfaceOptions();
});
