import { action } from 'satcheljs';
import { addCoreDatapointConfig } from 'owa-analytics-actions';
import getAllDayWellHeight from '../selectors/getAllDayWellHeight';

/**
 * Called to update the height of the collapsed all day well
 */

export const updateCollapsedAllDayWellHeight = action(
    'updateCollapsedAllDayWellHeight',
    (newValue: number) => {
        const userAllDayWellHeight = getAllDayWellHeight();

        if (userAllDayWellHeight === newValue) {
            return { newValue };
        }
        const expanding = userAllDayWellHeight < newValue;

        return addCoreDatapointConfig(
            {
                name: 'updateCollapsedAllDayWellHeight',
                customData: {
                    expanded: expanding,
                },
            },
            {
                newValue,
            }
        );
    }
);
