import {
    type OwaDate,
    addDays,
    differenceInCalendarDays,
    getISODateString,
    isAfter,
    startOfDay,
    isSameTime,
} from 'owa-datetime';
import type { DateRange } from 'owa-datetime-utils';
import {
    getOwaDateFromExtendedDateTime,
    isDateInDateRange,
    dateRangesOverlap,
} from 'owa-datetime-utils';

import type {
    FlexibleWorkingHoursView,
    GetScheduleFlexibleWorkingHoursView,
} from 'owa-graph-schema';

/**
 * Groups segments by date and returns of array of those mappings. Only includes the segments
 * that are in the requested date range, truncating the segments that span multiple days if needed.
 *
 * @param dateRangeToRetain The date range that the returned segments should be in.
 * @param segments The segments to group by date.
 * @param workingHoursPerDay The working hours per day to add the segments to.
 */
export default function groupSegmentsByDate(
    dateRangeToRetain: DateRange,
    segments: FlexibleWorkingHoursView[] | GetScheduleFlexibleWorkingHoursView[],
    workingHoursPerDay: Array<{
        id: string;
        segments: any[];
    }>
) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    segments.forEach(segment => {
        const segmentStartDate = getOwaDateFromExtendedDateTime(segment.start);
        const segmentEndDate = getOwaDateFromExtendedDateTime(segment.end);

        // Because segments can now span multiple days, we can't rely on just the start or end date
        // to determine if the segment is in the requested date range. We need to be inclusive of
        // the full range of dates the segment spans.
        const isSegmentInRequestedDateRange =
            dateRangesOverlap(
                { start: segmentStartDate, end: segmentEndDate },
                dateRangeToRetain,
                true /* inclusive */
            ) === 0;

        if (!isSegmentInRequestedDateRange) {
            return;
        }

        if (segment.workLocationType !== 'TimeOff') {
            addSegmentToDayResponses(workingHoursPerDay, segment, segmentStartDate);
        } else {
            addOOFSegmentRangeToDayResponses(
                workingHoursPerDay,
                segment,
                dateRangeToRetain,
                segmentStartDate,
                segmentEndDate
            );
        }
    });
}

function addSegmentToDayResponses(
    workingHoursPerDay: Array<{
        id: string;
        segments: any[];
    }>,
    segment: FlexibleWorkingHoursView | GetScheduleFlexibleWorkingHoursView,
    date: OwaDate
) {
    const formattedDateString = getISODateString(date);
    const foundDay = workingHoursPerDay.find(day => day.id === formattedDateString);

    if (foundDay && foundDay.segments) {
        foundDay.segments.push(segment);
    } else {
        workingHoursPerDay.push({
            id: formattedDateString,
            segments: [segment],
        });
    }
}

function addOOFSegmentRangeToDayResponses(
    workingHoursPerDay: Array<{
        id: string;
        segments: any[];
    }>,
    segment: FlexibleWorkingHoursView | GetScheduleFlexibleWorkingHoursView,
    dateRangeToRetain: DateRange,
    segmentStartDate: OwaDate,
    segmentEndDate: OwaDate
) {
    // If the segment start date is after the start of the date range to retain, then we use
    // the segment start date.
    const startDateInRequestedDateRange = isAfter(segmentStartDate, dateRangeToRetain.start)
        ? segmentStartDate
        : dateRangeToRetain.start;

    // If the segment end date is before the end of the date range to retain, then we use
    // the segment end date.
    const endDateInRequestedDateRange = isAfter(segmentEndDate, dateRangeToRetain.end)
        ? dateRangeToRetain.end
        : segmentEndDate;

    // If the OOF segment ends at midnight, we need to subtract a day from the date range
    // so that we aren't inclusive of the end date. This prevents the OOF segment from
    // being considered on the end date just because it ends at midnight of that date.
    const oofSegmentInDateRangeLength =
        differenceInCalendarDays(endDateInRequestedDateRange, startDateInRequestedDateRange) -
        (isSameTime(startOfDay(endDateInRequestedDateRange), endDateInRequestedDateRange) ? 1 : 0);

    for (let i = 0; i <= oofSegmentInDateRangeLength; i++) {
        const dateInOofRange = addDays(startDateInRequestedDateRange, i);

        if (isDateInDateRange(dateRangeToRetain, dateInOofRange)) {
            addSegmentToDayResponses(workingHoursPerDay, segment, dateInOofRange);
        }
    }
}
