import { getCalendarEntryByCalendarId } from '../selectors/calendarsCacheSelectors';
import type { CalendarEntry } from 'owa-graph-schema';

export default function getNonNullCalendarsFromCalendarIds(calendarIds: string[]): CalendarEntry[] {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (8,5): Type '(CalendarEntry | undefined)[] | null' is not assignable to type 'CalendarEntry[]'.
    // @ts-expect-error
    return calendarIds
        ? calendarIds.map(getCalendarEntryByCalendarId).filter(calendar => !!calendar) // Make sure we remove null calendars (this happens when cache is not loaded or when the group calendars have not loaded to the cache and we don't get calendar objects from the cache)
        : null;
}
