import { getDefaultCalendar } from 'owa-calendar-cache';
import type { CalendarEntry } from 'owa-graph-schema';
import type { AccountKey } from 'owa-client-types';

export function sortCalendarEntries(calendarEntries: CalendarEntry[], accountKey: AccountKey) {
    let isDefaultCalendarSelected = false;
    const defaultCalendar = getDefaultCalendar(accountKey);
    calendarEntries?.sort((a, b) => {
        if (
            (a.IsGroupMailboxCalendar && b.IsGroupMailboxCalendar) ||
            (!a.IsGroupMailboxCalendar && !b.IsGroupMailboxCalendar)
        ) {
            return 0;
        }
        if (a.IsGroupMailboxCalendar && !b.IsGroupMailboxCalendar) {
            return 1;
        }
        if (!a.IsGroupMailboxCalendar && b.IsGroupMailboxCalendar) {
            return -1;
        }

        return 0;
    });

    const filteredSelectedCalendars = calendarEntries?.filter(calendar => {
        if (calendar.calendarId.id === defaultCalendar?.calendarId.id) {
            isDefaultCalendarSelected = true;
            return false;
        } else {
            return true;
        }
    });

    return defaultCalendar
        ? isDefaultCalendarSelected
            ? [defaultCalendar, ...filteredSelectedCalendars]
            : calendarEntries
        : [];
}
