import {
    lazyRegisterDataCollectorIfNotRegistered,
    type DataCollector,
} from 'diagnostics-data-collection';
import loc from 'owa-localize';
import {
    getCalendarEntries,
    calendarFolderIdNeedsUpdate,
    getFolderIdByCalendarID,
} from '../selectors/calendarsCacheSelectors';
import type { CalendarEntry } from 'owa-graph-schema';
import { calendarCacheCollectorDescription } from './registerCalendarCacheCollector.locstring.json';

export async function registerCalendarCacheCollector() {
    const calendarCacheDataCollector: DataCollector = {
        dataCollectionAction: async () =>
            JSON.stringify({
                calendarEntries: getCalendarEntries().map(toDebugCalendarEntry),
            }),
        name: 'CalendarCacheDebugData',
        odsFileDescription: 'Calendar Cache data that includes all calendar cache data.',
        description: loc(calendarCacheCollectorDescription),
    };
    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(calendarCacheDataCollector);
}

function toDebugCalendarEntry(calendarEntry: CalendarEntry) {
    const calendarId = calendarEntry.calendarId?.id;
    return {
        CalendarId: calendarId,
        userIdentity: calendarEntry.calendarId?.mailboxInfo?.userIdentity,
        CalendarName: calendarEntry.CalendarName,
        CalendarFolderType: calendarEntry.CalendarFolderType,
        CalendarColor: calendarEntry.CalendarColor,
        HexColorString: calendarEntry.HexColorString,
        CanShare: calendarEntry.CanShare,
        IsGroupMailboxCalendar: calendarEntry.IsGroupMailboxCalendar,
        FolderId: getFolderIdByCalendarID(calendarId),
        FolderIdNeedsUpdate: calendarFolderIdNeedsUpdate(calendarId),
    };
}
