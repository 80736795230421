import { mutator } from 'satcheljs';
import { updateCollapsedAllDayWellHeight } from '../actions/updateCollapsedAllDayWellHeight';
import type { CalendarSurfaceOptions } from 'owa-outlook-service-option-store';
import { OwsOptionsFeatureType, getOptionsForFeature } from 'owa-outlook-service-option-store';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(updateCollapsedAllDayWellHeight, actionMessage => {
    const { newValue } = actionMessage;
    const userOptions = getOptionsForFeature<CalendarSurfaceOptions>(
        OwsOptionsFeatureType.CalendarSurfaceOptions
    );
    if (userOptions.allDayWellHeight != newValue) {
        userOptions.allDayWellHeight = newValue;
    }
});
