import {
    type OwaDate,
    addDays,
    getISODateString,
    startOfDay,
    getISOStringWithOffset,
    addMinutes,
    startOfWeek,
    endOfWeek,
} from 'owa-datetime';
import { TimeConstants } from 'owa-datetime-utils';
import type { FlexibleWorkingHoursView, GetWorkingHoursByDayResponse } from 'owa-graph-schema';
import type WorkingHoursType from 'owa-service/lib/contract/WorkingHoursType';

export const MAX_DATE_RANGE_IN_DAYS_STORE = 110;

export default function parseFlexibleWorkingHoursViewFromLegacy(
    date: OwaDate,
    legacyWorkingHours: WorkingHoursType
) {
    const startDate = startOfWeek(date, 0);
    const endDate = endOfWeek(date, 0);
    const workingHoursPerDay: GetWorkingHoursByDayResponse[] = [];
    const timeZoneId = legacyWorkingHours.WorkingHoursTimeZoneId ?? startDate.tz;

    if (
        legacyWorkingHours.WorkHoursStartTimeInMinutes &&
        legacyWorkingHours.WorkHoursEndTimeInMinutes &&
        legacyWorkingHours.WorkDays
    ) {
        for (let dayOfWeek = 0; dayOfWeek < TimeConstants.DaysInOneWeek; dayOfWeek++) {
            if (legacyWorkingHours.WorkDays & (1 << dayOfWeek)) {
                const dayOfWeekDate = startOfDay(addDays(startDate, dayOfWeek));
                const startTime = addMinutes(
                    dayOfWeekDate,
                    legacyWorkingHours.WorkHoursStartTimeInMinutes
                );
                const endTime = addMinutes(
                    dayOfWeekDate,
                    legacyWorkingHours.WorkHoursEndTimeInMinutes
                );
                const dateId = getISODateString(startTime);

                const segment: FlexibleWorkingHoursView = {
                    id: dateId,
                    start: {
                        dateTime: getISOStringWithOffset(startTime),
                        timeZone: {
                            name: timeZoneId,
                            __typename: 'TimeZoneWH',
                        },
                        __typename: 'ExtendedDateTime',
                    },
                    end: {
                        dateTime: getISOStringWithOffset(endTime),
                        timeZone: {
                            name: timeZoneId,
                            __typename: 'TimeZoneWH',
                        },
                        __typename: 'ExtendedDateTime',
                    },
                    workLocationType: 'Office' /* Default location for flexible working hours */,
                    occurrenceDetails: {},
                };

                workingHoursPerDay.push({
                    id: dateId,
                    segments: [segment],
                } as GetWorkingHoursByDayResponse);
            }
        }
    }

    const workingHoursDateRange = {
        start: getISODateString(startDate),
        end: getISODateString(endDate),
        workingHoursPerDay,
    };

    return workingHoursDateRange;
}
